<template>
  <main>
    <!-- <button class="btn btn-submit" @click="incentivi">incentivi</button> -->
    <div class="container-fluid cover">
      <div class="cover__layer">
        <div class="row">
          <div class="col text-center text-container">
            <h1 class="text-white">IP Monitor</h1>
            <h3 class="text-white">
              Il database dei bandi con misure anche per la tutela della proprietà intellettuale
            </h3>
            <span class="divider"></span>
            <h2 class="text-white">
              Trova il bando su misura per la tua impresa
            </h2>
          </div>
          <div class="row filter-container">
            <div class="col d-flex justify-content-center flex-column flex-md-row align-items-center">
              <div class="filter filter-text my-3 px-2"><input type="text" v-model.trim="search" placeholder="Filtra per titolo">
              </div>
             <div class="filter position-relative my-3">
               <div class="reset-filter" v-if="isOpen" @click="resetIsOpen"><i class="fa-solid fa-xmark"></i></div>
<!--               <label for="open" v-if="!isOpen">Filtra per</label>-->
                <select v-model="isOpen" class="select-css" id="open">
                  <option disabled selected value="">Filtra per</option>
                  <option value="true">Aperto</option>
                  <option value="false">Chiuso</option>
                </select>
            </div>
              <div class="filter position-relative my-3">
                <div class="reset-filter" v-if="dimension" @click="resetDimension"><i class="fa-solid fa-xmark"></i></div>
<!--                <label for="dimension" v-if="!dimension">Filtra per dimensioni</label>-->
                <select v-model="dimension" class="select-css" id="dimension">
                  <option disabled selected value="">Filtra per dimensione</option>
                  <option value="L">Locale</option>
                  <option value="N">Nazionale</option>
                  <option value="C">Comunitario</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Cards -->
    <div class="container py-5">
      <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 gy-3" v-if="risposta">
        <router-link :to="{name: 'about', params: {id: item.id}}" v-for="item in filterList" :key="item.id">
        <div class="my-card d-flex justify-content-between flex-column">
          <div class="p-3">
            <h4>{{item.meta_title}}</h4>
            <p>{{ item.entities[0].name }}</p>
          </div>
          <div class="d-flex justif">
            <div
              class="
                open-date-container
                col
                d-flex
                flex-column
                align-items-center
                justify-content-center
              "
            >
              <span class="open-date">Data apertura</span>
              <span class="date">{{myMoment(item.meta_opened_at)}}</span>
            </div>
            <div
              class="
                close-date-container
                col
                d-flex
                flex-column
                align-items-center
                justify-content-center
              "
            >
              <span class="open-date">Data Chiusura</span>
              <span class="date">{{ myMoment(item.meta_closed_at)  }}</span>
            </div>
          </div>
        </div>
        </router-link>
      </div> <div class="d-flex justify-content-center" v-else>
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    </div>
  </main>
</template>



<script>
import Axios from "axios/index";
import moment from 'moment';
import _ from 'lodash'

export default {

  methods: {
    getData() {
      return Axios.get(process.env.VUE_APP_FASI_URL + "incentives").then(
        (res) => {
          this.risposta = res.data;
          //console.log(this.risposta)
          return this.risposta;
        }
      );
    },
  myMoment(date){
    return moment(date).locale('it').format('DD MMM YYYY');
  },
    checkIfIsOpen(isOpen, openDate, endDate){
      let currentDate = new Date()
      currentDate = currentDate.toISOString()

      if (isOpen === 'true') {
        if (openDate < currentDate && endDate > currentDate) {
          return true
        } else {
          return false
        }
      } else {
        if (openDate > currentDate || endDate < currentDate) {
          return true
        } else {
          return false
        }
      }
    },
    resetIsOpen (){
      this.isOpen = ''
    },
    resetDimension(){
      this.dimension = ''
    }

  },
  created() {
    this.getData()
  },



  computed: {
    filterList() {
      return this.orderByDateRisposta.filter(obj => {
        if(this.search && this.isOpen && this.dimension) {
          return obj['meta_title'].toLowerCase().indexOf(this.search.toLowerCase()) !== -1 && this.checkIfIsOpen(this.isOpen, obj['meta_opened_at'],obj['meta_closed_at']) && obj['dimension'] === this.dimension
        } else if  (this.search && this.isOpen) {
          return obj['meta_title'].toLowerCase().indexOf(this.search.toLowerCase()) !== -1 && this.checkIfIsOpen(this.isOpen, obj['meta_opened_at'],obj['meta_closed_at'])
        } else if  (this.search && this.dimension) {
          return obj['meta_title'].toLowerCase().indexOf(this.search.toLowerCase()) !== -1 && obj['dimension'] === this.dimension
        } else if  (this.isOpen && this.dimension) {
          return  obj['dimension'] === this.dimension && this.checkIfIsOpen(this.isOpen, obj['meta_opened_at'],obj['meta_closed_at'])
        } else if (this.search) {
          return  obj['meta_title'].toLowerCase().indexOf(this.search.toLowerCase()) !== -1
        } else if (this.isOpen) {
          return this.checkIfIsOpen(this.isOpen, obj['meta_opened_at'],obj['meta_closed_at'])
        } else if (this.dimension) {
          return obj['dimension'] === this.dimension
        }


        return obj
      })
    },
    orderByDateRisposta() {
      return _.orderBy(this.risposta, ['meta_opened_at'], ['desc'])

    }

  },

  data() {
    return {
      risposta: '',
      callStatus: true,
      search: null,
      isOpen: '',
      dimension: '',
    };
  },
};
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
  color: initial;
}
.divider {
  border-bottom: 1px solid #c34770;
  height: 1px;
  width: 120px;
}
.filter-container {
  padding-bottom: 35px;
  padding-top: 20px;

  .filter-text {
    background-color: white;
    input {
      border: none;
      &:focus-visible {
        outline: none;
      }
    }
  }
}
.filter {
  width: 204px;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.text-container {
  margin-top: 48px;
  margin-bottom: 16px;
}
.search {
  margin: auto;
  max-width: 535px;
}
.hover {
  cursor: pointer;
  -webkit-transition: background-color 200ms linear;
  -moz-transition: background-color 200ms linear;
  -o-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  &:hover {
    background: rgb(237, 237, 237);
  }
}
.text {
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.2;
  height: 21px;
  clear: both;
  max-width: 100%;
  display: inline-block;
  white-space: nowrap;
  font-size: 18px !important;
}
.index {
  font-size: 16px;
  font-weight: 300;
}

.cover {
  background: url("../assets/cover.png") bottom no-repeat;
  background-size: cover;
  padding: 16px 0 60px 0;
}

.my-card {
  box-shadow: 0 0 15px rgb(0 0 0 / 15%);
  max-width: 100%;
  border-radius: 15px;
  min-height:270px;
  max-height: 270px;
  span{
    color: white;
  }
  .title-span {
    color: #06a2c9;
    font-size: 18px;
    font-weight: bold;
  }

  .date {
    font-size: 16px;
    font-weight: bold;

  }
  .open-date-container {
    background-color: #06a2c9;
    border-radius: 0 0 0 15px;
    color: white;
    max-width: 50%;
    height:70px;
    .open-date {
      font-size: 14px;
    }
  }
  .close-date-container {
    background-color: #c34770;
    color: white;
    border-radius: 0 0 15px;
    max-width: 50%;
    height: 70px;
    .open-date {
      font-size: 14px;
    }
  }
}
.select-css {
  display: block;
  font-size: 16px;
  font-family: sans-serif;
  //font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: .6em 1.4em .5em .8em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  border-radius: .5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
  linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;
}
.select-css::-ms-expand {
  display: none;
}
.select-css:hover {
  border-color: #888;
}
.select-css:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}
.select-css option {
  font-weight:normal;
}
.reset-filter{
  position: absolute;
  right: 50px;
}

label{
  position: absolute;
  color: #888888;
  left: 20px;
}
</style>