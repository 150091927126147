<template>
  <footer class="">
    <div class="">
      <div class="row row-cols-1 row-cols-md-3 py-5 justify-content-start">

        <!-- col contatti -->
        <div class="col py-3 d-flex justify-content-center">
          <div>
            <div class="contatti">
              <h4 class="text-white">CONTATTI</h4>
              <div class="textwidget">
               <p>Innexta</p>
               <p>Via Meravigli, 7</p>
               <p>20123 Milano</p>
               <p>-----------------</p>
               <p>Tel: <a href="tel:+39 02 8515 4264">+39 02 8515 4264</a></p>
               <p>E-mail: <a href="mailto:marchiedisegni@innexta.it">marchiedisegni@innexta.it</a></p>
              </div>
            </div>
          </div>
        </div>
        <!-- col ulteriori informazioni -->
        <div class="col text-white py-3 d-flex justify-content-center">
          <div class="bg-test">
            <ul class="test">

                <li><a href="https://www.marchiedisegni.eu/news/" class="test-a">News</a> </li>
                <li><a href="https://www.marchiedisegni.eu/?taxonomy=nav_menu&term=pagine">Approfondimenti</a></li>
                <li><a href="https://www.marchiedisegni.eu/eventi/">Eventi</a></li>
                <li><a href="https://www.marchiedisegni.eu/blockchain-e-proprieta-intellettuale/">BLOCKCHAIN & IP</a></li>
                <li><a href="https://www.marchiedisegni.eu/links/">Links</a></li>
                <li><a href="https://www.marchiedisegni.eu/pillole-video/">Pillole Video</a></li>
                <li><a href="https://www.marchiedisegni.eu/faq/">FAQ</a></li>
                <li><a href="https://www.marchiedisegni.eu/contatti/">Contatti</a></li>

            </ul>
            </div>
        </div>
      </div>
      <!-- blocco diritti -->
      <div class="d-flex justify-content-start social align-items-center">
        <div xs12>
          <div class="text-white text-xs-center big sans">
            © Copyright - 2022 | <a href="">Privacy Policy</a> e <a href="">Cookie Policy</a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss">
.contatti{
  p{
    font-size: 16px;
    color: #CCCCCC;
    font-family: Arial;
    margin: 0 0 15px;
  }

}
.bg-test{
  background-color: rgb(80, 80, 80);
  min-width: 338px;
}
.test{
  list-style-type: square;
  color: #2991D6;
  margin: 0;
  padding: 20px 0 10px 35px;
  li{
    height: 41px;
  }
  .test-a{
    padding: 8px 0 9px 3px;
    height: 41px;
  }
}
.container {
  padding: 20px;
}
.container-fluid {
  margin-bottom: 0;
}
.lead {
  letter-spacing: 1px;
  font-weight: normal;
  margin-bottom: 15px;
  font-size: 12px;
  font-style: italic;
}
.big {
  font-size: 14px;
  line-height: 26px;
  color: #cccccc !important;
}
footer {
  background: #545454;

}
.newsletter {
  border-radius: 3px;
  padding: 7px 11px;
  font-size: 1.2em;
  outline: none;
  margin-top: 20px;
  background: var(--v-secondary-base);
}
.my-btn {
  background-color: #33809e;
  padding: 7px 11px;
  border: none;
  border-radius: 5px;
  color: white;
}
.resources {
  padding: 75px 27.5px;
}
.social {
  border-top: 1px solid rgba(255, 255, 255, 0.18) !important;
  padding: 30px 27.5px 30px 27.5px;

}
a{
  color: #2991D6;
  text-decoration: none;
}

</style>