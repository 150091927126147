<template>
  <div
    class="container-fluid flex-column justify-content-between altezza"
  >
    <TheHeader />

    <TheMain />
    
    <TheFooter />
  </div>
</template>

<script>
// @ is an alias to /src
import TheHeader from '@/components/TheHeader.vue';
import TheMain from '@/components/TheMain.vue';
import TheFooter from '@/components/TheFooter.vue';

export default {
  components: {
    TheHeader,
    TheMain,
    TheFooter
  }
};
</script>

<style scoped lang="scss">
.altezza {
  min-height: 100vh;
}

main {
  flex-grow: 1;
  flex-shrink: 0;
  min-height: 1000px;
}

footer {
  flex-grow: 0;
}
</style>