<template>
    <header class="container-fluid py-3 px-2">
      <div class="row d-flex flex-row align-items-center">
        <div class="col-4">
          <a href="https://www.marchiedisegni.eu/" target="_blank">
            <img src="@/assets/logo_marchi_e_disegni.png" class="img-fluid logo" />
          </a>
        </div>
<!--        <div-->
<!--          class="col-8 d-none d-lg-flex justify-content-end align-items-center"-->
<!--        >-->
<!--          <ul class="d-flex justify-content-center align-items-center">-->
<!--            <li>-->
<!--              <a href="https://www.innexta.it" rel="nofollow">-->
<!--                <div>Home</div>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a href="https://www.innexta.it/cosa-facciamo/" rel="nofollow">-->
<!--                <div>Chi siamo</div>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a-->
<!--                href="https://www.innexta.it/finanza-complementare-fintech/"-->
<!--                rel="nofollow"-->
<!--              >-->
<!--                <div>Finanza complementare e FinTech</div>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a href="https://www.innexta.it/servizi-digitali/" rel="nofollow">-->
<!--                <div>Servizi Digitali</div>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a-->
<!--                href="https://www.innexta.it/progetti-speciali/"-->
<!--                rel="nofollow"-->
<!--              >-->
<!--                <div>Progetti Speciali</div>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a href="https://www.innexta.it/news/" rel="nofollow">-->
<!--                <div>News</div>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a href="https://www.innexta.it/user/" rel="nofollow">-->
<!--                <div>Area riservata</div>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li class="logo-unioncamere">-->
<!--              <a href="http://www.unioncamere.gov.it/" target="_blank">-->
<!--                <img width="40" src="@/assets/unioncamere_logo.png" />-->
<!--              </a>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
      </div>
    </header>
</template>



<style scoped lang="scss">
header {
  .logo {
    max-width: 350px;
    height: 60px;
  }
  .logo-unioncamere{
    display: flex;
    align-items: center;
    a{
      display: flex;
      align-items: center;
      &:hover{
        border-bottom: none;
      }
    }


  }
  ul {
    margin: 0;
  }
  li {
    list-style-type: none;
    padding: 10px 5px;
    display: flex;
    margin-right: 10px;

    a {
      color: #888;
      text-decoration: none;
      font-size: 16px;
      height: 30px;
      margin-bottom: -3px;
      padding-bottom: 0;
      &:hover{
        border-bottom: 2px solid #71b2e7;
      }

    }
  }
}
</style>