<template>
  <router-view/>
</template>

<style lang="scss">
@import'~bootstrap/dist/css/bootstrap.css';
@import '@fortawesome/fontawesome-free/css/all.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,700;1,100;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Patua+One&family=Roboto:ital,wght@0,700;1,100;1,400&display=swap');

.container-fluid{
  padding: 0;
}
body{
  //font-family: 'Roboto', sans-serif;
  color: #444444;
}
 h1{
     font-size: 54px;
     line-height: 60px;
     font-weight: 500;

   font-family: 'Patua One', cursive;
   }
   h2{
     font-size: 24px;
     line-height: 20px;
     font-weight: 400;
     font-family: 'Patua One', cursive;
   }
   h3{

     font-size: 18px;
     font-family: 'Patua One', cursive;
   }
   h4{
     font-weight: 300;
     font-size: 14px;
     font-size: 21px;
     color: #444444;
     line-height: 25px;
     font-family: 'Patua One', cursive;
   }
   h5 {
     font-weight: 700;
     font-size: 16px;
     font-family: 'Patua One', cursive;
   }
   p{
    color: #626262;
     //font-weight: 200;
   }
   span{
     color: #626262;
   }
   .v-text-field__details{
     display: none;
   }
   .primary-color{
     color: #06a2c9;
   }
</style>
